import "./toggle.scss";
import { useField } from "formik";

const ToggleFormik = (props) => {
  const [field] = useField(props);
  const checked = `${field.value}`;

  return (
    <div className="toggle">
      <label className="switch">
        <input
          type="checkbox"
          {...field}
          {...props}
          checked={checked === "true"}
          disabled={props.disabled}
        />
        <span className="slider round"></span>
      </label>
      <label htmlFor={props.label} className={props.classname}>
        {props.label}
      </label>
    </div>
  );
};

export default ToggleFormik;
