import { Form, Formik } from "formik";
import React from "react";
import { Input, InternationalPhoneInput } from "../../../../components";
import * as yup from "yup";
import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import "./userDetails.css";
import { NavigateButtonGroup } from "../index";
import { isValidPhoneNumber } from "libphonenumber-js";

import { useLazyQuery } from "@apollo/client";
import { SEARCH_BY_EMAIL } from "../../../../gqloperations/queries";
import { useHandleError } from "../../../../hooks/useHandleError";
import { getMembershipLevel } from "../../../../utils/getMembershipLevel";
import { getDateDifferenceInYears } from "../../../../utils/getDateDifferenceInYears";
function UserDetails() {
  const {
    handleMoveStepNext,
    setMemberInformation,
    memberInformation,
    memberData,
    setMemberData,
  } = useAddMemberContext();
  const userDetailsSchema = yup.object().shape({
    givenName: yup.string().required("First name is required"),
    surname: yup.string().required("Last name is required"),
    mobileNumber: yup
      .string()
      .required("Mobile number is required")
      .test("is-valid-number", "Invalid Number", (mobileNumber) => {
        return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
      }),
    dob: yup.string().required("DOB is Required"),

    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),

    emergencyContactName: yup.string().when("dob", {
      is: (dob) => {
        return getDateDifferenceInYears(dob) < 18;
      }, //just an e.g. you can return a function
      then: yup.string().required("Emergency name is required"),
    }),
    emergencyContactEmail: yup.string().when("dob", {
      is: (dob) => {
        return getDateDifferenceInYears(dob) < 18;
      }, //just an e.g. you can return a function
      then: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    emergencyContactRelation: yup.string().when("dob", {
      is: (dob) => {
        return getDateDifferenceInYears(dob) < 18;
      }, //just an e.g. you can return a function
      then: yup.string().required("Emergency relation is Required"),
    }),
    //  yup
    //   .string()
    //   .required("Emergency relation is Required"),

    emergencyContactNumber: yup.string().when("dob", {
      is: (dob) => {
        return getDateDifferenceInYears(dob) < 18;
      }, //just an e.g. you can return a function
      then: yup
        .string()
        .test("is-valid-number", "Invalid Number", (mobileNumber) => {
          return mobileNumber ? isValidPhoneNumber(mobileNumber) : false;
        }),
      // .required("Emergency relation is Required"),
    }),
  });
  const [getMemberByEmail] = useLazyQuery(SEARCH_BY_EMAIL);
  const handleError = useHandleError();
  const submitUserData = async (value, OnSubmitProps) => {
    const isExist = await getMemberByEmail({
      variables: { email: value.email.toUpperCase() },
    });
    if (isExist?.data?.getMemberByEmail?.items?.length > 0) {
      handleError("this email is already been used by another user!");
      return;
    }

    const age = getDateDifferenceInYears(value.dob);
    if (age < 8) {
      handleError("Member doesn't meet minimum age requirement!");
      return;
    }
    const membershipLevel = getMembershipLevel(age);

    setMemberData({
      ...memberData,
      memberDetails: {
        ...memberData.memberDetails,
        mobileNumber: value.mobileNumber,
        givenName: value.givenName,
        surname: value.surname,
        email: value.email,
        dob: value.dob,
        emergencyContactName: value.emergencyContactName,
        emergencyContactRelation: value.emergencyContactRelation,
        emergencyContactEmail: value.emergencyContactEmail,
        emergencyContactNumber: value.emergencyContactNumber,
      },
    });
    setMemberInformation({ ...memberInformation, age, membershipLevel });
    handleMoveStepNext();
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          givenName: "",
          surname: "",
          mobileNumber: "",
          email: "",
          dob: "",
          emergencyContactEmail: "",
          emergencyContactName: "",
          emergencyContactRelation: "",
          emergencyContactNumber: "",
        }}
        validationSchema={() => {
          return userDetailsSchema;
        }}
        onSubmit={async (values, OnSubmitProps) => {
          submitUserData(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form id="user-details-form">
              <div className="user-details-form">
                <p>CONTACT DETAILS</p>
                <div className="user-details-form-inputs">
                  <Input
                    type="name"
                    label="First name*"
                    name="givenName"
                    id="firstName"
                    autoComplete="off"
                  />
                  <Input
                    type="name"
                    label="Last name*"
                    name="surname"
                    id="lastName"
                    autoComplete="off"
                  />
                  <Input
                    type="name"
                    label="Email*"
                    name="email"
                    id="email"
                    autoComplete="off"
                  />
                  <InternationalPhoneInput
                    title="Mobile Number*"
                    name="mobileNumber"
                    id="mobileNumber"
                    editable={true}
                  />
                  <Input
                    type="date"
                    label="D.O.B*"
                    name="dob"
                    id="dob"
                    autoComplete="off"
                  />
                </div>
              </div>
              {getDateDifferenceInYears(formik.values.dob) < 18 && (
                <div className="user-details-form form-2">
                  <p>EMERGENCY CONTACT</p>
                  <div className="user-details-form-inputs">
                    <Input
                      type="name"
                      label="Emergency name*"
                      name="emergencyContactName"
                      id="emergencyContactName"
                      autoComplete="off"
                    />
                    <Input
                      type="name"
                      label="Emergency relationship*"
                      name="emergencyContactRelation"
                      id="emergencyContactRelation"
                      autoComplete="off"
                    />
                    <Input
                      type="name"
                      label="Emergency email*"
                      name="emergencyContactEmail"
                      id="emergencyContactEmail"
                      autoComplete="off"
                    />{" "}
                    <InternationalPhoneInput
                      title="Emergency Number*"
                      name="emergencyContactNumber"
                      id="emergencyContactNumber"
                      editable={true}
                    />
                  </div>
                </div>
              )}
              <NavigateButtonGroup />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UserDetails;
