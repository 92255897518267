import "./memberdetails.scss";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import React, { useState, useEffect, useRef } from "react";
import {
  addMonths,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
} from "date-fns";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import { CREATE_DOOR_ACCESS_LOG } from "../../gqloperations/mutations";
import { GET_LOCTAION_BY_ID } from "../../gqloperations/queries";
import AddCardToMember from "./AddCardToMember";
import { toast } from "react-toastify";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { API } from "../../utils/API";
import { isFitnessMembershipActive } from "../../utils/isContractActive";
import {
  Header,
  Button,
  PersonalInformation,
  Interactions,
  ContactDetails,
  EmergencyContact,
  BlockModal,
  AddNote,
  ManualMemberEntry,
  Sms,
  Modal,
  PaymentInformation,
  MakePaymentModal,
  StatusModal,
  CardInformation,
  MemberInteraction,
  MembershipInformation,
  BillingInformation,
  InputWithoutFormik,
  BannerDetails,
  FPIconNumber,
} from "../../components";
import { SET_MEMBER_ID } from "../../actions";
import { utcToZonedTime } from "date-fns-tz";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AiOutlineEdit } from "react-icons/ai";
import EditOustandingAmountModal from "../../components/modal/EditOustandingAmountModal";
import { useHandleError } from "../../hooks/useHandleError";

import useCustomFetch from "../../hooks/useCustomFetch";
import { getDateDifferenceInYears } from "../../utils/getDateDifferenceInYears";
const MemberDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [sidebarTimezone, setSidebarTimezone] = useState("");
  const [getSidebarLocation, { data }] = useLazyQuery(GET_LOCTAION_BY_ID, {
    onCompleted: () => {
      setSidebarTimezone(data.getLocation.timezone);
    },
  });

  const {
    member,
    createLog,
    memberId,
    getMemberById,
    memberloading,
    membererror,
    setIndex,
    index,
    getContracts,
    dispatch,
  } = useMemberContext();
  useEffect(() => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: id,
      },
    });
  }, [id]);
  const age = getDateDifferenceInYears(member?.getMember?.dob);

  const { permissions, locationAccess } = useAuthContext();
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [componentName, setComponentName] = useState("");
  const [showCardTable, setShowCardTable] = useState(true);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [activeMembership, setActiveMemberShip] = useState(0);
  const [isAwaitingSuspension, setIsAwaitingSuspension] = useState(false);
  const [suspendedMembership, setSuspendedMembership] = useState(0);
  const [membershipType, setMembershipType] = useState("");
  const [expiry, setExpiry] = useState("");
  const [outstandingAmount, setOustandingAmount] = useState("");
  const [debtCollectionStatus, setDebtCollectionStatus] = useState();
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const [timezone, setTimezone] = useState(
    member?.getMember?.homeLocationDetails?.timezone ?? "Australia/Sydney"
  );
  const [isFPmembershipActive, setIsFPmembershipActive] = useState(false);

  const handleError = useHandleError();
  const {
    data: bannerData,
    isLoading: bannerLoading,
    error: bannerError,
  } = useCustomFetch(endPoint && endPoint, params && params);
  const [createAccessLog] = useMutation(CREATE_DOOR_ACCESS_LOG, {
    variables: {
      input: {
        memberName: `${member?.getMember?.givenName} ${member?.getMember?.surname}`,
        memberId: member?.getMember?.memberId,
        deviceType: "MANUAL",
        homeLocationId: member?.getMember?.homeLocationId,
        memberHomeClub: member?.getMember?.homeLocationDetails.name,
        locationId: sidebarLocationId,
        isPostData: true,
        isOnline: true,
        logType: "POST",
        processLocationTime: format(
          utcToZonedTime(new Date(), sidebarTimezone),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
        ),
        isValid: true,
      },
    },
    onCompleted: () => {
      toast.success("Create access log successfully");
    },
    onError: (error) => {
      handleError(`${error}`);
    },
  });

  const handleAddMembership = () => {
    if (age < 8) {
      return toast.error("Member doesn't meet minimum age requirement");
    }
    navigate(`/dashboard/member/addMembershipToMember`);
  };

  useEffect(() => {
    setIsFPmembershipActive(
      isFitnessMembershipActive(member?.getMember?.contracts)
    );
    setOustandingAmount(member.getMember?.outstandingBalance);
    setDebtCollectionStatus(
      member.getMember?.debtCollectionDetails?.items[0]?.debtStatus
    );
  }, [member]);

  const handleBannerGenerate = () => {
    let parameters = {
      brandId: sidebarBrandId,
    };

    setEndPoint(API.bannderSummary(id));
    setParams(parameters);
  };

  useEffect(() => {
    if (sidebarBrandId && id) handleBannerGenerate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, id]);

  useEffect(() => {
    setIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleComponent = (componentname) => {
    setComponentName(componentname);
  };

  const handleClick = () => {
    setComponentName("");
    getMemberById();
    getContracts();
  };

  const handlePayClick = () => {
    setComponentName("");
    setShowModalPayment(true);
  };

  const handleCardClick = () => {
    setShowCardTable(false);
  };

  const handleCard = () => {
    setShowCardTable(true);
  };

  const handleAllowedBtn = (
    data,
    membershipCounts,
    isAwaitingSuspension,
    suspendedMembership
  ) => {
    if (data?.isBlocked) {
      return (
        <button
          name="Blocked"
          className={"btn btn-sm btn-red fs-12"}
          disabled={suspendedMembership > 0}
        >
          Blocked
        </button>
      );
    } else if (
      (membershipCounts?.cancelAmount && !membershipCounts?.activeAmount) ||
      (membershipCounts?.expiryAmount && !membershipCounts?.activeAmount)
    ) {
      return <></>;
    } else if (isAwaitingSuspension || suspendedMembership > 0) {
      return (
        <button
          name="Allowed"
          className={"btn btn-sm btn-disabled fs-12"}
          disabled={suspendedMembership > 0}
        >
          Allowed
        </button>
      );
    } else if (data.outstandingBalance && data.outstandingBalance > 0) {
      const isSentToDebtCollection =
        data?.debtCollectionDetails?.items[0]?.debtStatus === "SENT";
      return (
        <button name="Allowed" className={"btn btn-sm btn-red fs-12"}>
          Overdue
        </button>
      );
    } else {
      return (
        <button name="Alllowed" className={"btn btn-sm btn-green fs-12"}>
          Allowed
        </button>
      );
    }
  };
  useEffect(() => {
    if (memberId) {
      getMemberById();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  const { membershipAndBillingsForAContract, paymentDetails } =
    member && getContractAndBillingDetails(member);
  useEffect(() => {
    if (membershipAndBillingsForAContract?.length > 0) {
      setTimezone(
        member?.getMember?.homeLocationDetails?.timezone ?? "Australia/Sydney"
      );
    }
  }, [membershipAndBillingsForAContract, member]);
  const handleMembershipStatus = () => {
    let suspendedAmount = 0;
    let activeAmount = 0;
    let futureAmount = 0;
    let pendingAmount = 0;
    let cancelAmount = 0;
    let expiryAmount = 0;

    if (membershipAndBillingsForAContract?.length > 0) {
      for (let i = 0; i < membershipAndBillingsForAContract?.length; i++) {
        const currentDateTime = new Date();

        const isCancelled = membershipAndBillingsForAContract[i]?.expiryDateTime
          ? isBefore(
              startOfDay(
                utcToZonedTime(
                  new Date(
                    membershipAndBillingsForAContract[i]?.expiryDateTime
                  ),
                  timezone
                )
              ),
              new Date()
            ) ||
            isSameDay(
              utcToZonedTime(
                new Date(membershipAndBillingsForAContract[i]?.expiryDateTime),
                timezone
              ),
              new Date()
            )
          : false;

        if (isCancelled) {
          cancelAmount++;
          continue;
        }

        const isSuspended =
          membershipAndBillingsForAContract[i]?.suspensions?.items?.length >
            0 &&
          membershipAndBillingsForAContract[i]?.suspensions?.items?.some(
            (item) => {
              if (item.cancelledDateTime) return false;

              const suspensionStartTime = utcToZonedTime(
                item?.suspensionStartDateTime,
                timezone
              );

              const suspensionEndTime = utcToZonedTime(
                item?.suspensionEndDateTime,
                timezone
              );

              const isMedicalSusApproved =
                item?.suspensionType === "MEDICAL" &&
                item?.suspensionStatus === "APPROVED";

              const isStandardSusApproved = item?.suspensionType !== "MEDICAL";
              const isMiddleOfSuspension =
                isAfter(currentDateTime, startOfDay(suspensionStartTime)) &&
                isBefore(currentDateTime, endOfDay(suspensionEndTime));
              const isSuspensionActive =
                isSameDay(currentDateTime, suspensionStartTime) ||
                isSameDay(currentDateTime, suspensionEndTime) ||
                isMiddleOfSuspension;

              const isSuspensionValid =
                isSuspensionActive &&
                (isMedicalSusApproved || isStandardSusApproved);

              return isSuspensionValid;
            }
          );

        if (isSuspended) {
          suspendedAmount++;
          continue;
        }

        const isExpired = membershipAndBillingsForAContract[i]?.endDateTime
          ? isBefore(
              endOfDay(
                new Date(membershipAndBillingsForAContract[i]?.endDateTime)
              ),
              new Date()
            )
          : false;

        if (isExpired) {
          expiryAmount++;
          continue;
        }
        const isPending =
          membershipAndBillingsForAContract[i].isPending &&
          !membershipAndBillingsForAContract[i].startDateTime;

        if (isPending) {
          pendingAmount++;
          continue;
        }

        const currentDateInTimezone = utcToZonedTime(new Date(), timezone);

        const startDateTimeInTimezone = utcToZonedTime(
          membershipAndBillingsForAContract[i]?.startDateTime,
          timezone
        );

        const isFuture =
          membershipAndBillingsForAContract[i]?.startDateTime &&
          isAfter(
            startOfDay(startDateTimeInTimezone),
            startOfDay(currentDateInTimezone)
          );

        if (isFuture) {
          futureAmount++;
          continue;
        }

        const isAwaiting = membershipAndBillingsForAContract[
          i
        ]?.suspensions?.items?.filter(
          (item) =>
            item.suspensionStatus === "PENDING" &&
            item?.cancelledDateTime === null
        );

        isAwaiting.length > 0
          ? setIsAwaitingSuspension(true)
          : setIsAwaitingSuspension(false);

        activeAmount++;
      }

      setActiveMemberShip({
        activeAmount,
        futureAmount,
        pendingAmount,
        cancelAmount,
        expiryAmount,
      });
      setSuspendedMembership(suspendedAmount);
    }
    //TODO: currently the isSuspension field does not work properly, update and optimize this part once backend is fully functioned.
  };
  const chargeOustandingComplete = () => {
    handleClick();
    toast.success("Successfully charge member outstanding balance");
  };
  const onError = (error) => {
    handleError(
      "Error: Cannot charge member outstanding balance Message: " +
        error.message
    );
  };

  const listRef = useRef(null);
  const handleScrollLeft = () => {
    listRef.current.scrollLeft -= 100;
  };

  const handleScrollRight = () => {
    listRef.current.scrollLeft += 100;
  };

  const handleMembershipActive = () => {
    const activeMembership = [];
    if (membershipAndBillingsForAContract?.length > 0) {
      for (let i = 0; i < membershipAndBillingsForAContract?.length; i++) {
        const currentDateTime = new Date();

        const isCancelled = membershipAndBillingsForAContract[i]?.expiryDateTime
          ? isBefore(
              startOfDay(
                new Date(membershipAndBillingsForAContract[i]?.expiryDateTime)
              ),
              new Date()
            ) ||
            isSameDay(
              new Date(membershipAndBillingsForAContract[i]?.expiryDateTime),
              new Date()
            )
          : false;

        if (isCancelled) {
          // cancelAmount++;
          continue;
        }

        const isSuspended =
          membershipAndBillingsForAContract[i]?.suspensions?.items?.length >
            0 &&
          membershipAndBillingsForAContract[i]?.suspensions?.items?.some(
            (item) => {
              if (item.cancelledDateTime) return false;

              const suspensionStartTime = utcToZonedTime(
                item?.suspensionStartDateTime,
                timezone
              );

              const suspensionEndTime = utcToZonedTime(
                item?.suspensionEndDateTime,
                timezone
              );

              const isMedicalSusApproved =
                item?.suspensionType === "MEDICAL" &&
                item?.suspensionStatus === "APPROVED";

              const isStandardSusApproved = item?.suspensionType !== "MEDICAL";

              const isSuspensionActive =
                isSameDay(currentDateTime, suspensionStartTime) ||
                isSameDay(currentDateTime, suspensionEndTime) ||
                (isAfter(currentDateTime, startOfDay(suspensionStartTime)) &&
                  isBefore(currentDateTime, endOfDay(suspensionEndTime)));

              const isSuspensionValid =
                isSuspensionActive &&
                (isMedicalSusApproved || isStandardSusApproved);

              return isSuspensionValid;
            }
          );

        if (isSuspended) {
          // suspendedAmount++;
          continue;
        }

        const isExpired = membershipAndBillingsForAContract[i]?.endDateTime
          ? isBefore(
              endOfDay(
                new Date(membershipAndBillingsForAContract[i]?.endDateTime)
              ),
              new Date()
            )
          : false;

        if (isExpired) {
          // expiryAmount++;
          continue;
        }
        const isPending =
          membershipAndBillingsForAContract[i].isPending &&
          !membershipAndBillingsForAContract[i].startDateTime;

        if (isPending) {
          // pendingAmount++;
          activeMembership.push(membershipAndBillingsForAContract[i]);
          continue;
        }

        const currentDateInTimezone = utcToZonedTime(new Date(), timezone);

        const startDateTimeInTimezone = utcToZonedTime(
          membershipAndBillingsForAContract[i]?.startDateTime,
          timezone
        );

        const isFuture =
          membershipAndBillingsForAContract[i]?.startDateTime &&
          isAfter(
            startOfDay(startDateTimeInTimezone),
            startOfDay(currentDateInTimezone)
          );

        if (isFuture) {
          // futureAmount++;
          activeMembership.push(membershipAndBillingsForAContract[i]);
          continue;
        }

        const isAwaiting = membershipAndBillingsForAContract[
          i
        ]?.suspensions?.items?.filter(
          (item) =>
            (item.suspensionStatus === "PENDING" ||
              item.suspensionStatus === "APPROVED") &&
            item?.cancelledDateTime === null
        );

        isAwaiting.length > 0
          ? setIsAwaitingSuspension(true)
          : setIsAwaitingSuspension(false);

        // activeAmount++;
        activeMembership.push(membershipAndBillingsForAContract[i]);
      }
    }
    return activeMembership;
    //TODO: currently the isSuspension field does not work properly, update and optimize this part once backend is fully functioned.
  };
  useEffect(() => {
    const sixMonthFromToday = addMonths(new Date(), 6);
    if (membershipAndBillingsForAContract?.length > 0) {
      setLocationId(membershipAndBillingsForAContract[0]?.locationId);

      if (membershipAndBillingsForAContract[0]?.recurring) {
        setMembershipType("weekly");
      } else {
        setMembershipType("pif");
      }
      const endDateTimeArray = handleMembershipActive().map(
        (i) => i.endDateTime
      );
      const sortedEndDateTime = endDateTimeArray.sort(
        (a, b) => new Date(b).valueOf() - new Date(a).valueOf()
      );
      if (sortedEndDateTime.some((i) => i === null)) {
        setExpiry(
          format(
            utcToZonedTime(new Date(sixMonthFromToday), "Australia/Sydney"),
            "yyyy-MM-dd"
          )
        );
      } else {
        if (
          isBefore(
            utcToZonedTime(new Date(sortedEndDateTime[0]), "Australia/Sydney"),
            sixMonthFromToday
          )
        ) {
          setExpiry(
            format(
              utcToZonedTime(
                new Date(sortedEndDateTime[0]),
                "Australia/Sydney"
              ),
              "yyyy-MM-dd"
            )
          );
        } else
          setExpiry(
            format(
              utcToZonedTime(new Date(sixMonthFromToday), "Australia/Sydney"),
              "yyyy-MM-dd"
            )
          );
      }
    }

    handleMembershipStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(membershipAndBillingsForAContract)]);
  const EditOutstandingAmount = () => {
    return (
      <div className="outstanding-container">
        <div className="outstanding-amount">
          <InputWithoutFormik
            type="text"
            label="Amount:"
            name="amount"
            autoComplete="off"
            id="amount"
            className="fs-12 "
            value={Number(outstandingAmount).toFixed(2)}
            readOnly
          />
          <span
            className="icon-container edit edit-icon"
            onClick={() => {
              setShowModalPayment(false);
              handleComponent("edit-oustanding");
            }}
          >
            <AiOutlineEdit />
          </span>
        </div>
      </div>
    );
  };
  useEffect(() => {
    getSidebarLocation({
      variables: {
        id: sidebarLocationId ?? "",
      },
    });
  }, [sidebarLocationId]);
  if (memberloading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (membererror) return <div>Something Went Wrong!</div>;
  if (member?.getMember?.homeLocationId) {
    const memberLocationId = member?.getMember?.homeLocationId;
    const hasAccess = locationAccess.locationAll?.some(
      (id) => String(id) === String(memberLocationId)
    );
    if (!hasAccess) {
      return (
        <div className="dashboard-loading">
          You are not authorized to view this member as you don't have access to
          member's location!
        </div>
      );
    }
  }
  const isDebtSendToArma =
    debtCollectionStatus === "SENT" &&
    member?.getMember?.homeLocationDetails.debtCollectionType === "ARMA";
  return (
    <>
      <Header pageTitle="Member Details" />
      {member && (
        <>
          <div className="member-details">
            <div className="member-details-container">
              <div className="member-details-info">
                <div className="name">
                  {member.getMember.givenName} {member.getMember.surname}
                </div>
                {/* {member.getMember?.outstandingBalance && (
                  <div className="name fs-18 mt-32 red">{`Outstanding Balance: $${member.getMember?.outstandingBalance}`}</div>
                )} */}

                <div className="right flex-wrap">
                  <div className="member-id">
                    <div className="heading fs-10">Member ID</div>
                    <div className="member-id-container">
                      {/* <div className="fs-14">
                        {member.getMember.aliasMemberId}
                      </div> */}
                      <div className="member-card fs-10">1 Card</div>
                    </div>
                  </div>
                  <div className="member-type">
                    <div className="heading fs-10">Type</div>
                    <div className="icons">
                      {isFPmembershipActive ? (
                        <FPIconNumber
                          type="icon"
                          fitNumber={
                            isFPmembershipActive?.fitnessPassportNumber ?? null
                          }
                          memberDetails={member?.getMember}
                        />
                      ) : (
                        <div className="value green fs-10">
                          {member.getMember.type.substring(0, 1)}
                        </div>
                      )}
                    </div>
                  </div>

                  {activeMembership?.cancelAmount &&
                  !activeMembership?.activeAmount ? (
                    <Button
                      name={`cancelled`}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-sm btn-red btn-rounded fs-12"
                    />
                  ) : (
                    ""
                  )}
                  {activeMembership?.expiryAmount &&
                  !activeMembership?.activeAmount ? (
                    <Button
                      name={`expired`}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-sm btn-red btn-rounded fs-12"
                    />
                  ) : (
                    ""
                  )}
                  {activeMembership?.pendingAmount ? (
                    <Button
                      name={`${activeMembership?.pendingAmount} Pending`}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-sm btn-yellow btn-rounded fs-12"
                    />
                  ) : (
                    <></>
                  )}
                  {activeMembership?.activeAmount ? (
                    <Button
                      name={`${activeMembership.activeAmount} active`}
                      btntype="submit"
                      btnname="submit"
                      className="btn  btn-sm btn-green btn-rounded fs-12"
                    />
                  ) : (
                    ""
                  )}
                  {activeMembership?.futureAmount ? (
                    <Button
                      name={`${activeMembership?.futureAmount} future`}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-sm btn-yellow btn-rounded fs-12"
                    />
                  ) : (
                    ""
                  )}

                  {suspendedMembership > 0 && (
                    <Button
                      name={`${suspendedMembership} suspended`}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-sm btn-yellow btn-rounded fs-12"
                    />
                  )}

                  {outstandingAmount > 0 && (
                    <>
                      <div
                        className={
                          isDebtSendToArma ? "btn-disabled" : "allowed-btn"
                        }
                        onClick={() => handleComponent("outstanding-btn")}
                      >
                        <Button
                          name={
                            isDebtSendToArma
                              ? `Send to ARMA ($${Number(
                                  outstandingAmount
                                ).toFixed(2)})`
                              : `outstanding: $${Number(
                                  outstandingAmount
                                ).toFixed(2)}`
                          }
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-sm btn-red fs-12"
                        />
                      </div>
                    </>
                  )}
                  <div
                    className="allowed-btn"
                    onClick={() =>
                      !(suspendedMembership > 0) &&
                      !isAwaitingSuspension &&
                      !(outstandingAmount > 0) &&
                      handleComponent("allowed-btn")
                    }
                  >
                    {handleAllowedBtn(
                      member?.getMember,
                      activeMembership,
                      isAwaitingSuspension,
                      suspendedMembership
                    )}
                  </div>

                  {permissions.includes("CanAddNotes") && (
                    <div
                      className="access-btn"
                      onClick={() => handleComponent("note-btn")}
                    >
                      <button className="btn  btn-sm btn-transparent fs-12">
                        Note
                      </button>
                    </div>
                  )}
                  <div
                    onClick={() => {
                      if (sidebarLocationId !== 0) {
                        createAccessLog();
                      } else {
                        handleError(
                          "Please select the access location in sidebar"
                        );
                      }
                    }}
                  >
                    <button className="btn   btn-sm btn-transparent fs-12">
                      Entry
                    </button>
                  </div>
                  {permissions.includes("CanSendSmsToMember") && (
                    <div
                      className="access-btn"
                      onClick={() => handleComponent("sms")}
                    >
                      <button className="btn  btn-sm btn-transparent fs-12">
                        SMS
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {isFPmembershipActive &&
                !isFPmembershipActive?.fitnessPassportNumber && (
                  <div className="red-banner">
                    NO VALID FITNESS PASSPORT NUMBER
                  </div>
                )}
              <div className="tabs">
                <div className="tabs-wrapper">
                  <div onClick={handleScrollLeft} className="prev-icon">
                    <IoIosArrowDropleft />
                  </div>
                  <div ref={listRef} className="tabs-list">
                    <div
                      className={
                        index !== 0
                          ? "tab-head fs-14 back-mediumgray"
                          : "tab-head fs-14 back-persianblue"
                      }
                      onClick={() => {
                        setIndex(0);
                        createLog(
                          `Clicked Member Details tab for ${member.getMember.givenName} ${member.getMember.surname}`,
                          "member",
                          `${member.getMember.memberId}`
                        );
                      }}
                    >
                      Details
                    </div>
                    {permissions.includes("CanViewMemberContracts") && (
                      <div
                        className={
                          index !== 1
                            ? "tab-head fs-14 back-mediumgray"
                            : "tab-head fs-14 back-persianblue"
                        }
                        onClick={() => {
                          setIndex(1);
                          createLog(
                            `Clicked Memberships tab for ${member.getMember.givenName} ${member.getMember.surname}`,
                            "member",
                            `${member.getMember.memberId}`
                          );
                        }}
                      >
                        Memberships
                      </div>
                    )}

                    {permissions.includes("CanViewPaymentHistory") && (
                      <div
                        className={
                          index !== 2
                            ? "tab-head fs-14 back-mediumgray"
                            : "tab-head fs-14 back-persianblue"
                        }
                        onClick={() => {
                          setIndex(2);
                          createLog(
                            `Clicked Payments tab for ${member.getMember.givenName} ${member.getMember.surname}`,
                            "member",
                            `${member.getMember.memberId}`
                          );
                        }}
                      >
                        Payments
                      </div>
                    )}
                    <div
                      className={
                        index !== 3
                          ? "tab-head fs-14 back-mediumgray"
                          : "tab-head fs-14 back-persianblue"
                      }
                      onClick={() => {
                        setIndex(3);
                        createLog(
                          `Clicked Interactions tab for ${member.getMember.givenName} ${member.getMember.surname}`,
                          "member",
                          `${member.getMember.memberId}`
                        );
                      }}
                    >
                      Fobs
                    </div>
                    {permissions.includes("CanViewPaymentHistory") && (
                      <div
                        className={
                          index !== 4
                            ? "tab-head fs-14 back-mediumgray"
                            : "tab-head fs-14 back-persianblue"
                        }
                        onClick={() => {
                          setIndex(4);
                          createLog(
                            `Clicked Payments tab for ${member.getMember.givenName} ${member.getMember.surname}`,
                            "member",
                            `${member.getMember.memberId}`
                          );
                        }}
                      >
                        Passes
                      </div>
                    )}
                    {permissions.includes("CanViewPaymentHistory") && (
                      <div
                        className={
                          index !== 5
                            ? "tab-head fs-14 back-mediumgray"
                            : "tab-head fs-14 back-persianblue"
                        }
                        onClick={() => {
                          setIndex(5);
                          createLog(
                            `Clicked Payments tab for ${member.getMember.givenName} ${member.getMember.surname}`,
                            "member",
                            `${member.getMember.memberId}`
                          );
                        }}
                      >
                        Interactions
                      </div>
                    )}
                  </div>
                  <div onClick={handleScrollRight} className="next-icon">
                    <IoIosArrowDropright />
                  </div>
                </div>
                {/* {componentName === "make-payment" && ( */}
                {showModalPayment && (
                  <MakePaymentModal
                    amount={outstandingAmount}
                    setShowModalPayment={setShowModalPayment}
                    operation="OSB"
                    // getLocationId form a memberhomelocationid
                    locationId={member.getMember.homeLocationId}
                    onApiCompleted={() => chargeOustandingComplete()}
                    onApiError={(e) => onError(e)}
                    customProperties={EditOutstandingAmount}
                    debtCollectionId={
                      member.getMember?.debtCollectionDetails?.items[0]?.id
                    }
                    debtCollectionStatus={debtCollectionStatus}
                  />
                )}

                {/* )} */}
                {componentName && (
                  <Modal>
                    {componentName === "allowed-btn" &&
                      permissions.includes("CanAddMemberBlock") && (
                        <BlockModal
                          locationId={locationId}
                          memberId={id}
                          isUnblocking={member?.getMember?.isBlocked}
                          onClick={() => handleClick()}
                          membershipType={membershipType}
                          expiry={expiry}
                        ></BlockModal>
                      )}
                    {componentName === "outstanding-btn" && (
                      <StatusModal
                        modalHeaderTxt="Outstanding Balance"
                        modalParaText={`This member has an outstanding balance of $${Number(
                          outstandingAmount
                        ).toFixed(2)}`}
                        handleStatusClick={handlePayClick}
                        modalClose={() => setComponentName("")}
                        btnName={"pay"}
                      />
                    )}

                    {componentName === "note-btn" && (
                      <AddNote onClick={() => handleClick()} />
                    )}
                    {componentName === "entry-btn" && (
                      <ManualMemberEntry
                        className="testing"
                        onClick={() => handleClick()}
                      ></ManualMemberEntry>
                    )}
                    {componentName === "sms" && (
                      <Sms
                        className="testing"
                        onClick={() => handleClick()}
                      ></Sms>
                    )}
                    {componentName === "edit-oustanding" && (
                      <EditOustandingAmountModal
                        showModal={setComponentName}
                        showModalPayment={setShowModalPayment}
                        outstandingAmount={outstandingAmount}
                        setOutstandingAmount={setOustandingAmount}
                        setDebtCollectionStatus={setDebtCollectionStatus}
                        memberId={memberId}
                      ></EditOustandingAmountModal>
                    )}
                  </Modal>
                )}
                {/* MEMBER DETAILS TAB CONTENT */}
                <div className="tabContent pb-32" hidden={index !== 0}>
                  {/* Members Details content */}
                  <div className="member-info-row">
                    <PersonalInformation />
                    <div className="hide-on-shrink">
                      {bannerData?.data && !bannerLoading && !bannerError ? (
                        <BannerDetails data={bannerData?.data} />
                      ) : (
                        <div className="hide-on-shrink"></div>
                      )}
                    </div>
                  </div>

                  <div className="member-info-row">
                    <ContactDetails memberId={member.getMember.memberId} />
                    <div className="hide-on-shrink">
                      <Interactions
                        onClick={() => setIndex(5)}
                        memberId={memberId}
                        brandId={member.getMember.brandId}
                      />
                    </div>
                  </div>

                  <div className="member-info-row ">
                    <EmergencyContact />

                    <div className="spacer hide-on-shrink"></div>
                  </div>
                  <div className="member-info-row show-on-shrink ">
                    {bannerData?.data && !bannerLoading && !bannerError ? (
                      <BannerDetails data={bannerData?.data} />
                    ) : (
                      <div className="hide-on-shrink"></div>
                    )}
                  </div>
                  <div className="member-info-row show-on-shrink">
                    <Interactions
                      onClick={() => setIndex(5)}
                      memberId={memberId}
                      brandId={member.getMember.brandId}
                    />
                  </div>
                </div>
                {/* MEMBERSHIPS TAB CONTENT */}

                <>
                  {permissions.includes("CanViewMemberContracts") && (
                    <div className="tabContent" hidden={index !== 1}>
                      {Object.keys(membershipAndBillingsForAContract).length ===
                      0 ? (
                        <>
                          {permissions.includes("CanAddMembershipToMember") && (
                            <>
                              <div className="add-btn">
                                {member?.getMember?.isBlocked ? (
                                  <div className="email-btn-container">
                                    <span className="tooltip">
                                      <button
                                        type="button"
                                        className="gray-disabled btn-lg fs-12"
                                        disabled
                                      >
                                        Add Membership
                                      </button>
                                      <span className="tooltip-text fs-10">
                                        Unblock Member to add a membership
                                      </span>
                                    </span>
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-lg fs-12"
                                    onClick={() => handleAddMembership()}
                                  >
                                    Add Membership
                                  </button>
                                )}
                              </div>
                              <div className="no-data-holder ">
                                <div className="no-data">
                                  <h3 className="fs-12 uppercase">
                                    No Records Found
                                  </h3>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {permissions.includes("CanAddMembershipToMember") && (
                            <div className="add-btn">
                              {member?.getMember?.isBlocked ? (
                                <div className="email-btn-container">
                                  <span className="tooltip">
                                    <button
                                      type="button"
                                      className="gray-disabled btn-lg fs-12"
                                      disabled
                                    >
                                      Add Membership
                                    </button>
                                    <span className="tooltip-text fs-10">
                                      Unblock Member to add a membership
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-lg fs-12"
                                  onClick={() => handleAddMembership()}
                                >
                                  Add Membership
                                </button>
                              )}
                            </div>
                          )}
                          <div className="memberships-table">
                            <MembershipInformation
                              membershipAndBillingsForAContract={
                                membershipAndBillingsForAContract
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {permissions.includes("CanViewPaymentHistory") && (
                    <div className="tabContent" hidden={index !== 2}>
                      <div className="payments-main-container">
                        <div className="billing-info-container">
                          <PaymentInformation paymentDetails={paymentDetails} />
                        </div>
                        <div className="payment-table-container">
                          <BillingInformation
                            membershipAndBillingsForAContract={
                              membershipAndBillingsForAContract
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>

                <div className="tabContent" hidden={index !== 3}>
                  {showCardTable ? (
                    <>
                      <div className="add-btn ">
                        <button
                          type="button"
                          className={
                            membershipAndBillingsForAContract?.length === 0 &&
                            member?.getMember?.type !== "STAFF"
                              ? " btn btn-lightgray btn-disabled btn-lg fs-12"
                              : "btn btn-primary btn-lg fs-12"
                          }
                          onClick={handleCardClick}
                        >
                          Add Card
                        </button>
                      </div>

                      <div className="memberships-table">
                        <CardInformation />
                      </div>
                    </>
                  ) : (
                    <div className="memberships-table">
                      <AddCardToMember onClick={handleCard} />
                    </div>
                  )}
                </div>
                <div className="tabContent" hidden={index !== 5}>
                  <MemberInteraction />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

// serializes data
const getContractAndBillingDetails = (memberData) => {
  const getPaymentDetails = (paymentDetails) => {
    return paymentDetails.map((paymentDetail) => {
      if (paymentDetail.paymentType === "CREDIT_CARD") {
        return {
          primary: paymentDetail.primary,
          paymentType: paymentDetail.paymentType,
          cardNumber: paymentDetail.cardNumber,
          cardExpiryDate: paymentDetail.cardExpiryDate,
          cardCvv: paymentDetail.cardCvv,
          cardType: paymentDetail.cardType,
          cardHolderName: paymentDetail.cardHolderName,
          accountName: paymentDetail.accountName,
          accountNumber: paymentDetail.accountNumber,
          bsb: paymentDetail.bsb,
          createdAt: paymentDetail.createdAt,
          vivaPaymentInformationId: paymentDetail.vivaPaymentInformationId,
        };
      }
      if (paymentDetail.paymentType === "DIRECT_DEBIT") {
        return {
          primary: paymentDetail.primary,
          paymentType: paymentDetail.paymentType,
          accountNumber: paymentDetail.accountNumber,
          accountName: paymentDetail.accountName,
          bsb: paymentDetail.bsb,
          createdAt: paymentDetail.createdAt,
          vivaPaymentInformationId: paymentDetail.vivaPaymentInformationId,
        };
      }
      return {};
    });
  };
  let member = memberData;
  const {
    contracts: { items: contracts },
  } = member?.getMember;
  const {
    paymentDetails: { items: paymentDetails },
  } = member?.getMember;
  const membershipAndBillingsForAContract = contracts.map((contract) => {
    const { billings, ...membership } = contract;
    let { items: billingsItems } = billings;

    return {
      ...membership,
      billings: billingsItems,
    };
  });
  return {
    membershipAndBillingsForAContract,
    paymentDetails: getPaymentDetails(paymentDetails),
  };
};

export default MemberDetails;
