import "./personalInformation.scss";
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { Formik, Form } from "formik";
import { gender } from "../../constants/commonConstant";
import { useContext } from "react";
import { AuthContext } from "../../contexts";
import { useMutation } from "@apollo/client";
import { GET_MEMBER_BY_ID } from "../../gqloperations/queries";
import { format } from "date-fns";
import { useHandleError } from "../../hooks/useHandleError";
import {
  Button,
  Input,
  Select,
  StatusModal,
  Modal,
  HiddenDatePicker,
  InfoDisplayField,
} from "../../components/index";
import {
  UPDATE_MEMBER,
  CREATE_MEMBER_REQUEST,
} from "../../gqloperations/mutations";
import { personalInformationSchema } from "../../validations/Validation";
import { v4 as uuidv4 } from "uuid";
import { useMemberContext } from "../../contexts/MemberContext";
import ProfileImage from "../formUiElements/profileImage/ProfileImage";
import { BeatLoader } from "react-spinners";
const PersonalInformation = (props) => {
  const { permissions } = useAuthContext();
  const { loggedUser } = useContext(AuthContext);
  const handleError = useHandleError();
  const {
    member,
    createLog,
    downloadAndDecode,
    imgUrl,
    setImgUrl,
    encodeAndUploadImageToAws: uploadImageToAws,
    getMemberById,
  } = useMemberContext();

  const [isActive, setIsActive] = useState(true);
  const [requestType, setRequestType] = useState("");
  const [file, setFile] = useState();
  // eslint-disable-next-line no-unused-vars
  const [oldAttribute, setOldAttribute] = useState({
    givenName: `${member.getMember.givenName}`,
    surname: `${member.getMember.surname}`,
    dob: `${member.getMember.dob}`,
    gender: `${member.getMember.gender}`,
  });
  const [createMemberRequest] = useMutation(CREATE_MEMBER_REQUEST);

  useEffect(() => {
    (async () => {
      if (member?.getMember?.imageUrl)
        await downloadAndDecode(member.getMember.imageUrl);
    })();

    return () => {
      setImgUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member.getMember.imageUrl]);

  useEffect(() => {
    setOldAttribute({
      givenName: `${member?.getMember?.givenName}`,
      surname: `${member?.getMember?.surname}`,
      dob: `${member?.getMember?.dob}`,
      gender: `${member?.getMember?.gender}`,
    });
  }, [member]);

  const updateMemberImage = async () => {
    const extension = typeof file === "string" ? "png" : file?.name.split(".");

    // otherwise, upload normally
    const extensionName =
      typeof file === "string" ? "png" : extension[extension.length - 1];

    const updatedKey = `/memberImages/${
      member?.getMember?.memberId
    }/profile/${uuidv4()}.${extensionName}`;
    const uploadedFile = await uploadImageToAws(file, updatedKey);
    const imageUrl = uploadedFile?.key;
    return imageUrl;
  };
  const handleClick = () => {
    createLog(
      `Clicked Personal information edit icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
    setIsActive(false);
  };

  const handleCancel = async () => {
    toast.warning("Update Cancelled");
    document.getElementById("editEmergencyContactForm").reset();
    setIsActive(true);

    if (member.getMember.imageUrl) {
      await downloadAndDecode(member.getMember.imageUrl);
    } else {
      setImgUrl("");
    }

    createLog(
      `Clicked Personal information cancel icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  //calling a mutation
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const onSubmit = async (values, OnSubmitProps) => {
    let updatedFields = {};
    // dob:""
    // "2001-01-01"
    for (let key in values) {
      // If dob field has empty string, this means user did not edit it
      // so we should not update it in the BE
      // if (key === "dob" && values[key] === "") {
      //   continue;
      // }

      if (
        String(values[key]).toUpperCase() !==
        String(member.getMember[key]).toUpperCase()
      ) {
        updatedFields[key] = values[key].toUpperCase();
      }
    }

    if (file) {
      const imageUrl = await updateMemberImage();

      updatedFields.imageUrl = imageUrl;
    }
    await updateMember({
      variables: {
        input: { ...updatedFields, memberId: member.getMember.memberId },
      },
      onCompleted: () => {
        OnSubmitProps.resetForm();
        toast.success("Update successfully");
        setIsActive(true);
        createLog(
          `Clicked Personal information save icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
        getMemberById();
      },
      onError: (error) => {
        handleError(`${error}`);
        createLog(
          `Clicked Personal information save icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: `${member?.getMember?.memberId}`,
          },
        },
      ],
    });
    OnSubmitProps.setSubmitting(false);
  };

  const handleImageUpload = (e, base64Img) => {
    const file = e?.target?.files[0] || base64Img;
    if (!file) return;

    if (file.size > 5_242_880) {
      handleError("Image needs to be less than 5MB");
    } else {
      setFile(file);
      const clientSideImgUrl = base64Img
        ? base64Img
        : URL.createObjectURL(file);
      setImgUrl(clientSideImgUrl);
    }
  };

  const handleRequest = () => {
    createMemberRequest({
      variables: {
        memberId: member?.getMember?.memberId,
        requestedBy: loggedUser?.getMember.memberId,
        requestType,
      },
      onCompleted: () => {
        toast.success(
          requestType === "REQUEST_PHOTO"
            ? "New photo request sent successfully"
            : "Identification request sent successfully"
        );
      },
      onError: (error) => {
        handleError(`${error}`);
      },
    });
    setRequestType("");
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          imageUrl: `${member?.getMember?.imageUrl ?? ""}`,
          givenName: `${member?.getMember?.givenName}`,
          surname: `${member?.getMember?.surname}`,
          dob: `${member?.getMember?.dob ?? ""}`,
          gender: `${member.getMember.gender}`,
        }}
        validationSchema={personalInformationSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form
              id="editEmergencyContactForm"
              className="info-container pd-12 first personal"
            >
              <div className="personal-info">
                {formik.isSubmitting ? (
                  <div className="centered-loading">
                    <BeatLoader className="loading" color="white" size={15} />
                  </div>
                ) : (
                  <>
                    <ProfileImage
                      handleImageUpload={handleImageUpload}
                      imgUrl={imgUrl}
                      isActive={isActive}
                    />
                    <div className="input-container">
                      <div className="title-bar fs-14 pb-18 uppercase">
                        <h3>Personal Information</h3>
                        {permissions.includes("CanEditMember") && (
                          <>
                            {isActive ? (
                              <>
                                <span
                                  className="icon-container edit"
                                  onClick={handleClick}
                                >
                                  <AiOutlineEdit />
                                </span>
                              </>
                            ) : (
                              <span className="icon-container">
                                <div className="sub-container save">
                                  <Button
                                    iconname="BiSave"
                                    btntype="submit"
                                    btnname="submit"
                                    className="btn btn-edit save btn-lg fs-12 "
                                  />
                                </div>
                                <div className="sub-container cancel">
                                  <Button
                                    iconname="AiOutlineCloseCircle"
                                    btntype="reset"
                                    btnname="reset"
                                    className="btn  btn-edit cancel btn-lg fs-12"
                                    click={handleCancel}
                                  />
                                </div>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      <div className="info-body">
                        <div className="content-container">
                          <div className="info-row">
                            <Input
                              placeholder="From"
                              type="text"
                              label="Given Name * "
                              name="givenName"
                              id="givenName"
                              autoComplete="off"
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="info-row">
                            <Input
                              type="text"
                              label="Surname * "
                              name="surname"
                              id="surname"
                              autoComplete="off"
                              disabled={isActive ? true : false}
                              className={isActive ? "" : "blue"}
                            />
                          </div>
                          <div className="info-row">
                            <HiddenDatePicker
                              disabled={isActive}
                              name="dob"
                              label="Date of Birth"
                            />
                          </div>
                          <div className="info-row">
                            <InfoDisplayField
                              label="Age"
                              className="border-bottom-one"
                              value={
                                member?.getMember?.dob
                                  ? Math.floor(
                                      (new Date() -
                                        new Date(member.getMember.dob)) /
                                        (365.25 * 24 * 60 * 60 * 1000)
                                    )
                                  : ""
                              }
                            />
                          </div>

                          <div className="info-row">
                            <Select
                              label="Gender"
                              optionvalues={gender}
                              selecttype="array"
                              name="gender"
                              id="gender"
                              autoComplete="off"
                              disabled={isActive ? true : false}
                              className={
                                isActive
                                  ? "select-element-value"
                                  : "select-element-value blue"
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="button-container">
                        <Button
                          name="Request New Photo"
                          btntype="button"
                          className="btn-primary"
                          click={() => setRequestType("REQUEST_PHOTO")}
                        />
                        <Button
                          name="Request Identification"
                          btntype="button"
                          className="btn-primary"
                          click={() => setRequestType("REQUEST_IDENTIFICATION")}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      {requestType && (
        <Modal>
          {requestType === "REQUEST_PHOTO" && (
            <StatusModal
              modalHeaderTxt="Request new photo"
              modalParaText="Would you like to request a new photo for this member?"
              handleStatusClick={() => handleRequest("request-Photo")}
              modalClose={() => setRequestType("")}
            />
          )}
          {requestType === "REQUEST_IDENTIFICATION" && (
            <StatusModal
              modalHeaderTxt="Request identification"
              modalParaText="Would you like to request an identification for this member?"
              handleStatusClick={() => handleRequest("request-identification")}
              modalClose={() => setRequestType("")}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default PersonalInformation;
