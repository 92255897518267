import { format } from "date-fns";
import { getPaymentFrequencyString } from "../../../../utils/getDisplayString";
import { InfoDisplayField, Button } from "../../../index";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_MEMBER_BY_ID } from "../../../../gqloperations/queries";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { useMutation } from "@apollo/client";
import { CHANGE_MEMBERSHIP_FOR_MEMBER } from "../../../../gqloperations/mutations";
import { useParams } from "react-router-dom";
import MakePaymentAddMembershipModal from "../../../modal/MakePaymentAddMembershipModal";
import { useHandleError } from "../../../../hooks/useHandleError";

const ConfirmationTabContent = ({
  membershipData,
  confirmationData,
  handleBack,
  selectedLocation,
  member,
  navigate,
  currentMembership,
  oldMemberContractId,
}) => {
  const currentMemberContractId = useParams();
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [operation, setOperation] = useState("");
  const [amount, setAmount] = useState(0);
  const [payloadData, setPayloadData] = useState();
  const [membershipType, setMembershipType] = useState("");
  const { sidebarBrandName } = useMemberContext();
  const handleError = useHandleError();
  const [changeMembership, { loading: changeMembershipLoading }] = useMutation(
    CHANGE_MEMBERSHIP_FOR_MEMBER
  );
  const [currentMembershipDetails, setCurrentMembershipDetails] = useState({});
  useEffect(() => {
    const filterCurrentMembership = currentMembership.filter(
      (item) => item.id === currentMemberContractId.contractId
    );
    setCurrentMembershipDetails(filterCurrentMembership[0]);
    setPayloadData({
      changeMembershipArgumentsInput: {
        newMembershipId: membershipData.membershipDetails?.id,
        newLocationId: selectedLocation?.locationId,
        membershipId: filterCurrentMembership[0].membershipId,
      },
    });
    if (membershipData.membershipDetails.recurring) {
      setMembershipType("weekly");
    } else {
      setMembershipType("pif");
    }
    if (filterCurrentMembership[0].costPrice >= membershipData.costPrice) {
      setOperation("MDO");
      setAmount(
        Number(confirmationData.downgradeFee) + confirmationData.cancellationFee
      );
    } else {
      setOperation("MUU");
      setAmount(
        Number(confirmationData.upfrontPaymentAmount) +
          confirmationData.cancellationFee
      );
    }
  }, [member]);
  const changeMembershipSuccess = () => {
    navigate(`/dashboard/member/${member?.getMember.memberId}`);
    setShowModalPayment(false);
    toast.success("Change membership successfully");
  };
  const changeMembershipErr = (err) => {
    handleError(`${err}`);
  };
  const refetchQueries = [
    {
      query: GET_MEMBER_BY_ID,
      variables: {
        memberId: member?.getMember.memberId,
      },
    },
  ];
  const handleShowVivapay = () => {
    setShowModalPayment(true);
  };

  const handleChangeMembership = () => {
    changeMembership({
      variables: {
        memberId: member?.getMember.memberId,
        oldMemberContractId: oldMemberContractId?.contractId,
        newMembershipId: membershipData.membershipDetails?.id,
        membershipLocationId: membershipData?.id,
        getInformation: false,
      },
      onCompleted: () => {
        changeMembershipSuccess();
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: member?.getMember.memberId,
          },
        },
      ],
      onError: (error) => {
        changeMembershipErr();
      },
    });
  };
  return (
    <>
      <div className="confirmation-UG-container">
        <div className="info-card mt-32">
          <div className="summary-content flex-col text-gray fs-12">
            <h3>SUMMARY AND CONFIRMATION</h3>
            <div className="flex-row first">
              <InfoDisplayField
                label="Membership Start Date:"
                value={format(new Date(), "EEE dd/MM/yy")}
              />
            </div>
            <div className="flex-row">
              <InfoDisplayField
                label="Membership Type"
                value={membershipData.membershipDetails?.membershipName}
              />
              <InfoDisplayField
                label="Home Location"
                value={selectedLocation.locationName}
              />
            </div>
            {/* <label>Membership Pro Rata</label> */}
            <div className="pro-rata">
              {membershipData.membershipDetails.recurring && (
                <p>
                  Your first Direct Debit of{" "}
                  <span>${confirmationData.debitCost}</span> will be processed{" "}
                  <span>
                    {confirmationData.firstDDDate
                      ? format(
                          new Date(confirmationData.firstDDDate),
                          "EEEE dd/MM/yyyy"
                        )
                      : " "}
                  </span>{" "}
                  and every{" "}
                  <span>
                    {getPaymentFrequencyString(
                      membershipData.membershipDetails.paymentFrequency
                    )}
                  </span>{" "}
                  thereafter
                </p>
              )}
            </div>

            {/* TODO: don't understand the meaning of this content in figma, waiting for confirmation */}
            {/* <p>
            Please note: Because Direct Debit is occurring today we won’t be
            able to determine if you will be charged your new rate or old rate.
            If you have any concerns please process this request after 2pm
            today.
          </p> */}
            <div className="flex-row">
              <InfoDisplayField
                label="Membership Amount"
                value={`$${Number(confirmationData.debitCost).toFixed(2)}`}
              />
              <InfoDisplayField
                label="Upgrade/Downgrade Fee"
                value={`$${
                  currentMembershipDetails.costPrice >= membershipData.costPrice
                    ? confirmationData.downgradeFee
                    : confirmationData.upfrontPaymentAmount
                }`}
              />
              <InfoDisplayField
                label="Membership Pro Rata"
                value={`$${confirmationData.prorata}`}
              />
              {currentMembershipDetails.recurring &&
                currentMembershipDetails.contractLength && (
                  <InfoDisplayField
                    label="Cancellation fee"
                    value={`$${Number(confirmationData.cancellationFee).toFixed(
                      2
                    )}`}
                  />
                )}
            </div>
            <div className="flex-row">
              <InfoDisplayField
                label="Today’s Payment"
                value={`$${(
                  Number(confirmationData.upfrontPaymentAmount) +
                  confirmationData.cancellationFee
                ).toFixed(2)}`}
                // value={`$${upfrontPayment} `}
              />
            </div>
            <div className="flex-row first">
              <InfoDisplayField label="Staff Code (optional)" value="" />
            </div>
          </div>
        </div>
        <div className="right-btn mt-18">
          <Button
            name="Back"
            btntype="reset"
            btnname="reset"
            className="btn btn-deny btn-large fs-12"
            click={handleBack}
          />
          <Button
            name="Confirm"
            btntype="submit"
            btnname="submit"
            click={() => {
              if (
                sidebarBrandName.toLowerCase() === "plus fitness" &&
                currentMembershipDetails.costPrice >=
                  membershipData.costPrice &&
                !confirmationData.cancellationFee
              ) {
                handleChangeMembership();
              } else {
                handleShowVivapay();
              }
            }}
            className="btn btn-confirm fs-12"
            loading={changeMembershipLoading}
          />
        </div>
        {showModalPayment && (
          <MakePaymentAddMembershipModal
            amount={amount}
            membershipType={membershipType}
            operation={operation}
            setShowModalPayment={setShowModalPayment}
            locationId={member?.getMember.contracts.items[0].locationId}
            payloadData={payloadData}
            onApiCompleted={() => changeMembershipSuccess()}
            onApiError={(e) => changeMembershipErr(e)}
            apiRefetchQueries={refetchQueries}
          />
        )}
      </div>
    </>
  );
};
export default ConfirmationTabContent;
