import React, { useEffect, useState } from "react";
import InputWithoutFormik from "../formUiElements/input/InputWithoutFormik";
import { Formik, Form, Field } from "formik";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import { useMemberContext } from "../../contexts/MemberContext";
import { useMutation } from "@apollo/client";
import { useHandleError } from "../../hooks/useHandleError";
import {
  ADD_MEMBER_PAYMENT_INFORMATION,
  CHARGE_SINGLE_PAYMENT,
} from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import Modal from "./Modal";
import "./modal.scss";
const MakePaymentAddMembershipModal = (props) => {
  const { member, sidebarBrandId } = useMemberContext();
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);
  const [
    addMemberPaymentInfo,
    { data: addMemberPaymentInfoData, loading: addMemberPaymentInfoLoading },
  ] = useMutation(ADD_MEMBER_PAYMENT_INFORMATION);
  const [cardRequestId, setCardRequestId] = useState("");
  const [paymentType, setPaymentType] = useState("cardOnFile");
  const [paymentInformation, setPaymentInformation] = useState({});
  const [openVivaPay, setOpenVivaPay] = useState(false);
  const [futurePayment, setFuturePayment] = useState("oldCardAsDefault");
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    holderName: "",
    accountNumber: "",
    bsb: "",
  });
  const handleError = useHandleError();
  const [futurePaymentType, setFuturePaymentType] = useState("");
  const [validBankAccount, setValidBankAccount] = useState(true);
  const [enablePaymentBtn, setEnablePaymentBtn] = useState(false);

  const iframeSrc = `${process.env.REACT_APP_VIVAPAY_IFRAME}/${cardRequestId}`;

  const handleSubmit = async (values) => {
    try {
      let currentPaymentOption = "CARD_ON_FILE_IS_DEFAULT";
      let futurePaymentOption = "CARD_ON_FILE_IS_DEFAULT";

      if (["EFTPOS", "CASH", "WAIVE"].includes(paymentType)) {
        currentPaymentOption = paymentType;
      } else if (
        (paymentType === "differentCard" || Object.keys(cardPrimary).length) &&
        futurePayment === "oldCardAsDefault"
      ) {
        currentPaymentOption = "CARD_ON_FILE_IS_DEFAULT";
      } else if (
        (paymentType === "differentCard" &&
          futurePayment === "newCardAsDefault") ||
        paymentType === "creditCard" ||
        futurePaymentType === "creditCard"
      ) {
        currentPaymentOption =
          paymentType === "EFTPOS" || paymentType === "CASH"
            ? "CARD_ON_FILE_IS_DEFAULT"
            : "DIFFERENT_CARD_IS_DEFAULT";
        futurePaymentOption = "DIFFERENT_CARD_IS_DEFAULT";
      }

      if (
        futurePaymentType === "bankAccount" ||
        futurePayment === "bankAccount"
      ) {
        futurePaymentOption = "BANK_ACCOUNT_IS_DEFAULT";
      }
      const payload = {
        amount: Number(props.amount),
        memberId: props.memberDetail?.memberId || member?.getMember?.memberId,
        brandId: sidebarBrandId,
        paymentInformationId:
          paymentType !== "cardOnFile" || futurePaymentType === "creditCard"
            ? paymentInformation?.paymentInformation?.paymentInformationId
            : cardPrimary?.id,
        operation: props.operation,
        futurePaymentOption,
        currentPaymentOption,
        bankAccountDetails:
          futurePayment === "bankAccount" || futurePaymentType === "bankAccount"
            ? paymentInfoBank
            : undefined,
        ...(props?.payloadData && { ...props.payloadData }),
      };

      if (paymentType === "EFTPOS") {
        if (props.operation === "AMS") {
          payload.addMembershipArguments = {
            ...payload.addMembershipArguments,
            invoice: values.transactionNumber,
          };
        } else if (props.operation === "RNM") {
          payload.renewMembershipArguments = {
            ...payload.renewMembershipArguments,
            invoice: values.transactionNumber,
          };
        } else if (props.operation === "MUU" || props.operation === "MDO") {
          payload.changeMembershipArguments = {
            ...payload.changeMembershipArguments,
            invoice: values.transactionNumber,
          };
        } else if (props.operation === "MAV") {
          payload.membershipActivationArguments = {
            ...payload.membershipActivationArguments,
            invoice: values.transactionNumber,
          };
        }
      }
      if (props.operation === "MAV") {
        payload.membershipActivationArguments = {
          ...payload.membershipActivationArguments,
          membershipId: props.membershipId,
        };
      }
      const data = await chargeSinglePayment({
        variables: payload,
        onCompleted: props?.onApiCompleted
          ? (data) => props?.onApiCompleted(data)
          : () => {},
        onError: props?.onApiError ? (e) => props?.onApiError(e) : () => {},
        refetchQueries: props?.apiRefetchQueries,
      });

      if (!data.errors) {
        props.setShowModalPayment(false);
      }
    } catch (e) {
      handleError(e.message);
    }
  };

  const openModalVivaPay = async () => {
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: "GET_SAVE_CARD_TOKEN_REQUEST",
            memberId: props.memberDetail?.memberId
              ? props.memberDetail?.memberId
              : member?.getMember?.memberId,
            requestApplication: "HUB",
            locationId: props.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.cardRequestId) {
        setOpenVivaPay(true);
        setCardRequestId(data.data?.addMemberPaymentInformation.cardRequestId);
      }
    } catch (e) {
    } finally {
    }
  };
  const closeModalVivaPay = async () => {
    setOpenVivaPay(false);
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: "ADD_CREDIT_CARD_REQUEST",
            memberId: props.memberDetail?.memberId
              ? props.memberDetail?.memberId
              : member?.getMember?.memberId,
            cardRequestId: cardRequestId,
            requestApplication: "HUB",
            locationId: props.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.paymentInformationId) {
        setPaymentInformation({
          ...paymentInformation,
          paymentInformation: data.data?.addMemberPaymentInformation,
        });
        toast.success("Save card successfully");
      } else {
        if (paymentType === "differentCard") {
          setPaymentType("cardOnFile");
        } else {
          setPaymentType("");
        }
        handleError("Save card fail");
      }
    } catch (e) {
    } finally {
    }
  };
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };
  const [cardPrimary, setCardPrimary] = useState({});
  useEffect(() => {
    const isCreditCardIncomplete =
      futurePaymentType === "creditCard" &&
      paymentInformation?.paymentInformation?.cardNumber;
    const isBankAccountInComplete =
      futurePaymentType === "bankAccount" &&
      paymentInfoBank.holderName &&
      paymentInfoBank.accountNumber &&
      paymentInfoBank.bsb &&
      validBankAccount;
    const isCardAvailable =
      (paymentType !== "differentCard" && cardPrimary?.id) ||
      (paymentType !== "differentCard" &&
        (props.membershipType === "pif" ||
          props.membershipType === "fitnessPassport")) ||
      ((paymentType === "differentCard" || paymentType === "creditCard") &&
        paymentInformation?.paymentInformation?.cardNumber);

    const isEnable =
      isCreditCardIncomplete || isBankAccountInComplete || isCardAvailable;
    setEnablePaymentBtn(isEnable);
  }, [
    paymentType,
    futurePaymentType,
    paymentInfoBank,
    validBankAccount,
    cardPrimary,
    paymentInformation,
  ]);

  useEffect(() => {
    setPaymentInformation({});
  }, [paymentType]);

  useEffect(() => {
    if (Object.keys(props.memberDetail || {}).length) {
      props.memberDetail?.paymentDetails?.items?.map((i) => {
        if (i?.primary && i?.paymentType === "CREDIT_CARD") {
          setCardPrimary(i);
        }
      });
    } else {
      member?.getMember?.paymentDetails?.items?.map((i) => {
        if (i?.primary && i?.paymentType === "CREDIT_CARD") {
          setCardPrimary(i);
        }
      });
    }
  }, [member, props.memberDetail]);

  return (
    <div>
      <Modal>
        <Formik
          enableReinitialize
          initialValues={{
            cardType: "",
            cardNumber: "",
            expiry: "",
            nameOnCard: "",
            cardCvv: "",
            holderName: "",
            accountNumber: "",
            bsb: "",
            transactionNumber: "",
          }}
          validate={(values, props) => {
            let errors = {};

            if (
              futurePaymentType === "bankAccount" ||
              futurePayment === "bankAccount"
            ) {
              if (!values?.holderName.trim()) {
                errors.holderName = "Please enter account name";
              }
              if (!values?.accountNumber.trim()) {
                errors.accountNumber = "Please enter account number";
              } else if (!/^\d+$/.test(values?.accountNumber.trim())) {
                errors.accountNumber =
                  "Account number should contain only digits";
              }
              if (!values?.bsb.trim()) {
                errors.bsb = "Please enter BSB";
              } else if (!/^\d{6}$/.test(values?.bsb.trim())) {
                errors.bsb = "BSB should be 6 digits";
              }
            }
            if (
              paymentType === "EFTPOS" &&
              values.transactionNumber &&
              !/^\d+$/.test(values?.transactionNumber.trim())
            ) {
              errors.transactionNumber =
                "Transaction number should contain only digits";
            }
            if (Object.keys(errors).length > 0) {
              setValidBankAccount(false);
            } else {
              setValidBankAccount(true);
            }
            return errors;
          }}
          onSubmit={(values, OnSubmitProps) => {
            handleSubmit(values);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="status-modal make-a-payment-modal pd-32">
                  <div className="make-payment-title ">
                    <h3 className="fs-35 pb-24 uppercase">Make a Payment</h3>
                  </div>{" "}
                  <div>
                    <div className="member-details-modal-container">
                      <h3>Member Details</h3>
                      <div className="member-details-inputs pb-24">
                        <InputWithoutFormik
                          type="text"
                          label="member No:"
                          name="memberNo"
                          autoComplete="off"
                          id="memberNo"
                          className="fs-12"
                          value={
                            member?.getMember?.aliasMemberId
                              ? member?.getMember?.aliasMemberId
                              : props.memberDetail?.aliasMemberId
                          }
                          readOnly
                        />
                        <InputWithoutFormik
                          type="text"
                          label="member Name:"
                          name="memberName"
                          autoComplete="off"
                          id="memberName"
                          className="fs-12"
                          value={
                            member?.getMember?.givenName
                              ? member?.getMember?.givenName
                              : props.memberDetail?.givenName
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="outstanding-payments-container pb-24">
                      <h3>{props.paymentType}</h3>
                      <InputWithoutFormik
                        type="text"
                        label="Amount:"
                        name="amount"
                        autoComplete="off"
                        id="amount"
                        className="fs-12"
                        value={Number(props.amount).toFixed(2) || ""}
                        readOnly
                      />
                    </div>
                    <h3 className="pb-8">
                      Credit Card surcharge will be added to single payments
                      only.
                      <br />
                      <p className="pt-8">
                        There is no fee for ongoing fortnightly memberships.
                      </p>
                    </h3>
                    <h3 className="pb-8">
                      (VISA/MASTSERCARD: 1.5%, AMEX: 3.5%)
                    </h3>
                  </div>
                  {Object.keys(cardPrimary).length !== 0 &&
                  cardPrimary?.vivaPaymentInformationId &&
                  cardPrimary?.vivaPaymentInformationId !== "" ? (
                    <>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={`payment-options-container pb-18 fs-12
                        `}
                      >
                        <label>
                          <Field
                            name="cardOnFile"
                            type="radio"
                            checked={paymentType === "cardOnFile"}
                            onClick={(e) => {
                              setPaymentType("cardOnFile");
                            }}
                          />
                          USE CARD ON FILE
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="differentCardAsDefault"
                            checked={paymentType === "differentCard"}
                            onClick={(e) => {
                              setPaymentType("differentCard");
                              openModalVivaPay();
                            }}
                          />
                          USE DIFFERENT CARD
                        </label>

                        <>
                          <label>
                            <Field
                              type="radio"
                              name="paymentType"
                              checked={paymentType === "EFTPOS" || false}
                              onClick={(e) => {
                                setPaymentType("EFTPOS");
                                // openModalVivaPay();
                              }}
                            />
                            EFTPOS
                          </label>{" "}
                          <label>
                            <Field
                              type="radio"
                              name="paymentType"
                              checked={paymentType === "CASH" || false}
                              onClick={(e) => {
                                setPaymentType("CASH");
                                // openModalVivaPay();
                              }}
                            />
                            CASH
                          </label>
                        </>
                      </div>
                      <div>
                        {paymentType === "cardOnFile" && (
                          <div className="payment-details-modal-container">
                            <Input
                              disabled={true}
                              type="text"
                              label="Type: "
                              name="cardType"
                              id="cardType"
                              autoComplete="off"
                              value={
                                cardPrimary.cardType ? cardPrimary.cardType : ""
                              }
                              className="fs-12"
                            />
                            <Input
                              disabled={true}
                              type="text"
                              label="Card"
                              name="cardNumber"
                              id="cardNumber"
                              autoComplete="off"
                              value={
                                cardPrimary?.cardNumber
                                  ? cardPrimary?.cardNumber
                                  : ""
                              }
                              className="fs-12"
                            />
                            <Input
                              disabled={true}
                              type="text"
                              label="Expiry"
                              name="expiry"
                              id="expiry"
                              autoComplete="off"
                              value={
                                cardPrimary?.cardExpiryDate
                                  ? cardPrimary?.cardExpiryDate
                                  : ""
                              }
                              className="fs-12"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      role="group"
                      aria-labelledby="my-radio-group"
                      // className=" payment-options-container pb-18 fs-12"
                      className={`${
                        props.operation === "RNM" ||
                        props.operation === "AMS" ||
                        props.operation === "MUU" ||
                        props.operation === "MDO" ||
                        props.operation === "MAV"
                          ? "payment-options-container"
                          : "payment-options-container-1"
                      } pb-18 fs-12
                      `}
                    >
                      <label>
                        <Field
                          type="radio"
                          name="paymentType"
                          checked={paymentType === "creditCard" || false}
                          onClick={(e) => {
                            setPaymentType("creditCard");
                            openModalVivaPay();
                          }}
                        />
                        PAY WITH CREDIT CARD
                      </label>

                      <>
                        <label>
                          <Field
                            type="radio"
                            name="paymentType"
                            checked={paymentType === "EFTPOS" || false}
                            onClick={(e) => {
                              setPaymentType("EFTPOS");
                              // openModalVivaPay();
                            }}
                          />
                          EFTPOS
                        </label>{" "}
                        <label>
                          <Field
                            type="radio"
                            name="paymentType"
                            checked={paymentType === "CASH" || false}
                            onClick={(e) => {
                              setPaymentType("CASH");
                              // openModalVivaPay();
                            }}
                          />
                          CASH
                        </label>
                      </>
                    </div>
                  )}
                  {(paymentType === "differentCard" ||
                    paymentType === "creditCard") &&
                    paymentInformation.paymentInformation && (
                      <div className="payment-details-modal-container">
                        <Input
                          disabled={true}
                          type="text"
                          label="Type: "
                          name="cardType"
                          id="cardType"
                          autoComplete="off"
                          value={
                            paymentInformation.paymentInformation?.cardType
                              ? paymentInformation?.paymentInformation.cardType
                              : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Card"
                          name="cardNumber"
                          id="cardNumber"
                          autoComplete="off"
                          value={
                            paymentInformation?.paymentInformation.cardNumber
                              ? paymentInformation?.paymentInformation
                                  .cardNumber
                              : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Expiry"
                          name="expiry"
                          id="expiry"
                          autoComplete="off"
                          value={
                            paymentInformation?.paymentInformation
                              ?.cardExpiryDate
                              ? paymentInformation?.paymentInformation
                                  ?.cardExpiryDate
                              : ""
                          }
                          className="fs-12"
                        />
                      </div>
                    )}
                  {paymentType === "EFTPOS" && (
                    <div className="payment-details-modal-container">
                      <Input
                        type="text"
                        label="Transaction number:"
                        name="transactionNumber"
                        id="transactionNumber"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                  )}
                  {/* {memberContractId: {eq: "memberContractId"}} */}
                  {(paymentType === "CASH" || paymentType === "EFTPOS") &&
                  !cardPrimary.id &&
                  props.membershipType !== "pif" &&
                  props.membershipType !== "fitnessPassport" ? (
                    <div>
                      <div className="future-payment-container">
                        <h3>FUTURE PAYMENT OPTIONS</h3>
                        <p>
                          Select payment method to procceeded future payment
                        </p>
                        <div className="payment-types-container">
                          <div
                            className="payment-type"
                            onClick={() => {
                              setFuturePaymentType("creditCard");
                              if (
                                !paymentInformation.paymentInformation
                                  ?.cardNumber
                              ) {
                                openModalVivaPay();
                              }
                            }}
                          >
                            <label>
                              <Field
                                type="radio"
                                name="futurePaymentType"
                                value="creditCard"
                              />
                              Use Credit Card
                            </label>
                          </div>
                          <div
                            className="payment-type"
                            onClick={() => setFuturePaymentType("bankAccount")}
                          >
                            <label>
                              <Field
                                type="radio"
                                name="futurePaymentType"
                                value="bankAccount"
                              />
                              Use a bank account
                            </label>
                          </div>
                        </div>
                      </div>

                      {paymentInformation.paymentInformation?.cardNumber &&
                        futurePaymentType === "creditCard" && (
                          <div className="payment-details-modal-container">
                            <Input
                              disabled={true}
                              type="text"
                              label="Type: "
                              name="cardType"
                              id="cardType"
                              autoComplete="off"
                              value={
                                paymentInformation.paymentInformation?.cardType
                              }
                              className="fs-12"
                            />
                            <Input
                              disabled={true}
                              type="text"
                              label="Card"
                              name="cardNumber"
                              id="cardNumber"
                              autoComplete="off"
                              value={
                                paymentInformation.paymentInformation
                                  ?.cardNumber
                              }
                              className="fs-12"
                            />
                            <Input
                              disabled={true}
                              type="text"
                              label="Expiry"
                              name="expiry"
                              id="expiry"
                              autoComplete="off"
                              value={
                                paymentInformation.paymentInformation
                                  ?.cardExpiryDate
                              }
                              className="fs-12"
                            />
                          </div>
                        )}
                      {(paymentType === "CASH" || paymentType === "EFTPOS") &&
                        futurePaymentType === "bankAccount" && (
                          <div className="payment-details-modal-container bank-account">
                            <Input
                              type="text"
                              label="Account name: "
                              name="holderName"
                              id="holderName"
                              autoComplete="off"
                              className="fs-12"
                              value={formik.values.holderName || ""}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setDataBank(e);
                              }}
                              onBlur={formik.handleBlur}
                            />
                            <Input
                              type="text"
                              label="Account number"
                              autoComplete="off"
                              name="accountNumber"
                              id="accountNumber"
                              className="fs-12"
                              value={formik.values.accountNumber || ""}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setDataBank(e);
                              }}
                              onBlur={formik.handleBlur}
                            />
                            <Input
                              type="text"
                              label="BSB"
                              name="bsb"
                              id="bsb"
                              autoComplete="off"
                              className="fs-12"
                              value={formik.values.bsb || ""}
                              onChange={(e) => {
                                formik.handleChange(e);
                                setDataBank(e);
                              }}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        )}
                    </div>
                  ) : (
                    <div>
                      {props.membershipType &&
                        props.membershipType === "weekly" &&
                        paymentType !== "EFTPOS" &&
                        paymentType !== "CASH" && (
                          <div>
                            <div className="future-payment fs-14  noselect pb-24">
                              {paymentType === "differentCard" && (
                                <h3 className="future-title">
                                  Future Payment Options
                                </h3>
                              )}

                              {paymentType === "differentCard" && (
                                <label>
                                  <Field
                                    type="checkbox"
                                    name="differentCard"
                                    checked={
                                      futurePayment === "newCardAsDefault"
                                    }
                                    onChange={() => {
                                      setFuturePayment((prevState) =>
                                        prevState === "newCardAsDefault"
                                          ? null
                                          : "newCardAsDefault"
                                      );
                                    }}
                                  />
                                  Use this card for future payments
                                </label>
                              )}
                            </div>
                            {futurePayment === "bankAccount" && (
                              <div className="payment-details-modal-container bank-account">
                                <Input
                                  type="text"
                                  label="Account name: "
                                  name="holderName"
                                  id="holderName"
                                  autoComplete="off"
                                  className="fs-12"
                                  value={paymentInfoBank.holderName || ""}
                                  onChange={(e) => setDataBank(e)}
                                />
                                <Input
                                  type="text"
                                  label="Account number"
                                  autoComplete="off"
                                  name="accountNumber"
                                  id="accountNumber"
                                  className="fs-12"
                                  value={paymentInfoBank.accountNumber || ""}
                                  onChange={(e) => setDataBank(e)}
                                />
                                <Input
                                  type="text"
                                  label="BSB"
                                  name="bsb"
                                  id="bsb"
                                  autoComplete="off"
                                  className="fs-12"
                                  value={paymentInfoBank.bsb || ""}
                                  onChange={(e) => setDataBank(e)}
                                />
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  )}
                  <div className="make-payment-btn-container ">
                    <Button
                      loading={
                        addMemberPaymentInfoLoading ||
                        chargeSinglePaymentLoading
                      }
                      name={"Make payment"}
                      btntype="submit"
                      btnname="submit"
                      className={`btn btn-primary btn-lg fs-12  ${
                        !enablePaymentBtn ? "btn-disabled" : ""
                      }`}
                      disabled={
                        !enablePaymentBtn ||
                        addMemberPaymentInfoLoading ||
                        chargeSinglePaymentLoading
                      }
                    />
                    <Button
                      name="CANCEL"
                      btntype="button"
                      btnname="cancel"
                      click={() => props.setShowModalPayment(false)}
                      className="btn btn-deny btn-lg fs-12"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {openVivaPay && (
        <Modal>
          <div className="status-modal make-a-payment-modal viva-pay pd-32">
            <iframe
              id="pay-with-vivapay"
              title="pay with vivapay"
              src={iframeSrc}
              className="iframe-vivapay"
              loading="lazy"
            />
            <Button
              name="Continue"
              btntype="button"
              btnname="cancel"
              className="btn btn-confirm btn-small"
              click={() => closeModalVivaPay()}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
export default MakePaymentAddMembershipModal;
