const InfoDisplayField = ({ label, value }) => {
  return (
    <>
      <div className="flex-col border-bottom-one">
        <h5 className="fs-12 mb-5">{label}</h5>
        <p className="fs-12">{value}</p>
      </div>
    </>
  );
};
export default InfoDisplayField;
